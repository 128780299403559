"use client";

import Badge from "@/components/Badge";
import Card from "@/components/Card";
import useAccounts from "@/hooks/useAccounts";
import { Account } from "@/types/types";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Link from "next/link";
import AddAccountForm from "./AddAccountForm";

export default function AccountsList({
  userAccountId,
}: {
  userAccountId: string;
}) {
  const accounts = useAccounts(userAccountId);
  const [animationParent] = useAutoAnimate();

  if (!accounts) return <LoadingAccountsList />;

  return accounts.length === 0 ? (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4">
      <EmptyAccountsList />
      <AddAccountForm userAccountId={userAccountId} />
    </div>
  ) : (
    <div
      ref={animationParent}
      className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4">
      <AccountsListWithData accounts={accounts} />
      <AddAccountForm userAccountId={userAccountId} />
    </div>
  );
}

export function LoadingAccountsList() {
  return (
    <Card className="h-48 items-center justify-center p-4">
      <span className="font-bold">Loading...</span>
    </Card>
  );
}

export function EmptyAccountsList() {
  return (
    <Card className="h-48 items-center justify-center p-4">
      <span className="font-bold">Accounts List Empty</span>
    </Card>
  );
}

export function AccountsListWithData({ accounts }: { accounts: Account[] }) {
  return (
    <>
      {accounts.map((account) => (
        <AccountItem key={account.id} account={account} />
      ))}
    </>
  );
}

function AccountItem({ account }: { account: Account }) {
  return (
    <Card className="h-48 transition hover:scale-105 active:scale-95">
      <Link
        className="flex h-full flex-col justify-end rounded p-4 hover:bg-gray-100 dark:hover:bg-gray-700 gap-2"
        href={`/accounts/${account.id}`}>
        {account.archivedAt && (
          <div className="flex">
            <Badge>Archived</Badge>
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex gap-2 text-sm">
            <span>Balance:</span>
            {account.balance != null && (
              <span className="text-gray-600 dark:text-gray-400">
                ₱{account.balance.toLocaleString()}
              </span>
            )}
          </div>
          <span className="text-xl font-semibold">{account.name}</span>
        </div>
      </Link>
    </Card>
  );
}
