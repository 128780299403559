export default function Card({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={`flex flex-col rounded border dark:border-gray-700 ${className}`}>
      {children}
    </div>
  );
}
