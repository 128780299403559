type InputBasicProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};

export default function InputBasic(props: InputBasicProps) {
  const { label, ...rest } = props;

  return (
    <div className="flex flex-col">
      {label && (
        <label className="text-sm text-gray-600 dark:text-gray-400">
          {label}
        </label>
      )}
      <input
        className="rounded border border-gray-300 px-4 py-2 dark:border-gray-700 dark:bg-gray-800"
        {...rest}
      />
    </div>
  );
}
