import { getFirebase } from "@/lib/getFirebase";
import { getAccountsPath } from "@/lib/paths";
import { Account } from "@/types/types";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export default function useAccounts(userAccountId: string) {
  const { db } = getFirebase();
  const path = getAccountsPath(userAccountId);
  const q = query(collection(db, path), where("deletedAt", "==", null));
  const [accounts, setAccounts] = useState<Account[]>([]);

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const accounts: Account[] = [];

    querySnapshot.forEach((doc) => {
      accounts.push({
        id: doc.id,
        ...doc.data(),
      } as Account);
    });

    const onlyUnarchivedAccounts = accounts.filter(
      (account) => account.archivedAt == null
    );

    setAccounts(onlyUnarchivedAccounts);
  });

  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, [unsubscribe]);

  return accounts;
}
