"use client";

import { getFirebase } from "@/lib/getFirebase";
import { ButtonBasic } from "./ButtonBasic";

function logout() {
  const { auth } = getFirebase();

  auth.signOut().catch((e) => {
    console.error("Sign Out Error", e);
  });
}

export default function ButtonLogout() {
  return (
    <ButtonBasic variant="secondary" onClick={() => logout()}>
      Logout
    </ButtonBasic>
  );
}
