"use client";

import { ButtonBasic } from "@/components/ButtonBasic";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import InputBasic from "@/components/InputBasic";
import { addAccount } from "@/lib/account";
import React, { useState } from "react";

export default function AddAccountForm({
  userAccountId,
}: {
  userAccountId: string;
}) {
  const [isClosed, setIsClosed] = useState<boolean>(true);

  return (
    <>
      {isClosed ? (
        <AddAccountCard setIsClosed={setIsClosed} />
      ) : (
        <AccountFormCard
          userAccountId={userAccountId}
          setIsClosed={setIsClosed}
        />
      )}
    </>
  );
}

function AddAccountCard({
  setIsClosed,
}: {
  setIsClosed: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Card className="h-48 transition hover:scale-105 active:scale-95">
      <div
        onClick={() => setIsClosed(false)}
        className="flex h-full cursor-pointer flex-col items-center justify-center gap-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 sm:gap-4">
        <Icon code="add" />
        <span>New Account</span>
      </div>
    </Card>
  );
}

function AccountFormCard({
  userAccountId,
  setIsClosed,
}: {
  userAccountId: string;
  setIsClosed: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [name, setName] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (name.length < 0) {
      setIsSubmitting(false);
      return;
    }
    e.preventDefault();
    await addAccount({ userAccountId, name });
    setName("");
    setIsSubmitting(false);
  };

  return (
    <Card className="h-48">
      <form
        className="flex h-full flex-col justify-end gap-4 p-4"
        onSubmit={submit}>
        <div className="flex flex-col">
          <span className="font-semibold">Create a new account</span>
          <span className="text-sm">Your transactions will be stored here</span>
        </div>
        <InputBasic
          type="text"
          placeholder="Account Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          disabled={isSubmitting}
          required
        />
        <div className="flex justify-end gap-2">
          <ButtonBasic
            variant="secondary"
            type="button"
            onClick={() => setIsClosed(true)}>
            Cancel
          </ButtonBasic>
          <ButtonBasic
            className="w-full sm:w-auto"
            type="submit"
            disabled={isSubmitting}>
            Add
          </ButtonBasic>
        </div>
      </form>
    </Card>
  );
}
