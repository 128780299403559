"use client";

import getUserAccount from "@/lib/userAccount";
import { FirebaseUser, UserAccount } from "@/types/types";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";

export default function useUserAccount(): UserAccount | undefined {
  const [userAccount, setUserAccount] = useState<UserAccount>();
  const { user } = useAuth();

  const getUserAccountFromFirebase = (user: FirebaseUser) => {
    if (user) {
      getUserAccount(user).then((userAccount) => {
        setUserAccount(userAccount);
      });
    }
  };

  useEffect(() => {
    if (user) {
      getUserAccountFromFirebase(user);
    }
  }, [user]);

  return userAccount;
}
